body {
  color: rgb(123, 109, 109) !important;
  background-color: #fbfaf6;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-padding-top: 145px; /* height of sticky header */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "TheSeasonsRegular";
  src: url("./TheSeasonsRegular/font.woff2") format("woff2"),
    url("./TheSeasonsRegular/font.woff") format("woff");
}
