.home-link {
  font-family: "EB Garamond", serif;
  letter-spacing: 1.5px;
  font-style: italic;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  margin: 1rem 2rem 1rem 2rem;
  border: 2px solid rgb(123, 109, 109);
  color: rgb(123, 109, 109);
  display: block;
  max-width: 315px;
  text-align: center;
}
.rsvp-title {
  letter-spacing: 1rem;
  margin-top: 3rem;
}

#rsvp-note {
  margin-left: 0px;
}
#success-message {
  margin-left: 0px;
  font-size: 18px;
}
.counter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.counter {
  text-align: center;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  margin: 0 10px;
  padding: 5px 10px;
  font-size: 20px;
  font-weight: bold;
  color: rgb(123, 109, 109);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: transparent;
}

.button:hover {
  background-color: #abadb0;
}

.counter-display {
  font-size: 24px;
  margin: 0 10px;
}

.guest-list {
  margin-top: 20px;
  text-align: left;
}

.guest {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.guest input {
  width: 48%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

@media (min-width: 992px) {
  .pew-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .pew {
    max-width: 75%;
  }
}
