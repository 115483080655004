#Nav {
  padding: 25px 25px 10px 25px !important;
  font-size: 12px !important;
  background-color: #fbfaf6 !important;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); */
}

.mobile-name {
  font-size: 13px;
  letter-spacing: 2px;
}
.joyful {
  font-size: 13px;
  margin-bottom: 50px;
  font-family: "EB Garamond", serif;
  letter-spacing: 1.5px;
  font-style: italic;
}

.mobile-img {
  width: 100%;
}

.mobile-date {
  margin-block: 30px;
}
.mobile-date > .cinzel {
  font-size: 12px;
}
.mobile-date > .rancho {
  text-align: center;
}

h3.garamond {
  font-size: 25px;
}
.container-fluid > .navbar-collapse {
  padding-inline: 15px;
}

button > span {
  font-size: 11px;
  letter-spacing: 2px;
}
.navbar-toggler {
  border: none !important;
}

#Travel {
  margin-bottom: 20px;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: rgb(123, 109, 109);
}
.ls-4 {
  letter-spacing: 5px;
}

.sub-head {
  font-size: 20px;
}

#modal-close {
  position: absolute;
  right: 0;
  margin: 12px 12px 0;
  opacity: 1;
}
.padding-5 {
  padding: 0;
}

#Modal-Image {
  border-radius: 5px;
}
.first-section {
  display: grid;
  align-content: center;
}
.first-img {
  display: none;
}
.d-sm-flex > .faqs {
  align-content: baseline;
}

.garamond {
  font-family: "EB Garamond", serif;
  color: rgb(123, 109, 109);
}
#Our {
  margin-top: 130px;
  display: flex;
  flex-direction: column-reverse;
}

.seasons {
  font-family: "TheSeasonsRegular", serif;
}

.seasons-sm {
  font-family: "TheSeasonsRegular", serif;
}
.garamond-it {
  font-family: "EB Garamond", serif;
  letter-spacing: 1.5px;
  font-style: italic;
  font-size: 16px;
}
.cinzel {
  font-family: "Cinzel Decorative", serif;
  letter-spacing: 2px;
  margin-bottom: 2px;
}
.rancho {
  letter-spacing: 2px;
  font-size: 10px;
}

.mt-nav {
  margin-top: 130px;
}
.logo {
  padding-inline: 1rem;
  font-size: 32px !important;
}
.space-center {
  justify-content: space-between;
  align-self: stretch;
  width: 100%;
}
.space-center > a {
  margin-inline: 1%;
}

.space-between {
  justify-content: space-around;
  align-self: stretch;
  width: 100%;
}
.title {
  margin-top: 1rem;
}
.d-lg-flex > * {
  flex: 1;
}
.sorry {
  display: none;
}
.grid {
  display: grid;
}
.middle-grid {
  display: grid;
  align-content: end;
}

.middle-grid > * {
  justify-self: center;
}

.grid-4,
.grid-3 {
  display: grid;
  grid-auto-flow: column;
}
.padding-l {
  padding-left: 0;
}

@media (min-width: 576px) {
  .garamond-it {
    font-size: 22px;
    margin-bottom: 1rem;
    letter-spacing: 5px;
    margin-left: 5%;
  }

  #Event {
    letter-spacing: 3px;
  }

  #Nav {
    padding-bottom: 25px !important;
  }
  .d-sm-flex > * {
    max-width: 50%;
  }
  nav {
    padding: 40px 100px !important;
  }
}
@media (min-width: 992px) {
  .mobile-welcome {
    display: none;
  }
  .main-story {
    display: grid;
    align-content: center;
    grid-auto-flow: column;
  }
  .d-lg-flex > .pew {
    flex: 0;
  }
  #Travel {
    margin-bottom: 0;
  }
  .seasons {
    font-size: 17px;
    line-height: 24px;
  }
  .sorry {
    display: block;
    padding-right: 25px;
  }
  #Our {
    margin-top: 130px;
    flex-direction: column;
  }
  .first-img {
    width: 460px;
    display: block;
  }
  .title {
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  h5.guest {
    min-height: 64px;
  }
  .space-center > a {
    margin-top: 18px;
    margin-inline: 2%;
  }
  .padding-5 {
    padding: 2rem 2rem 2rem 0;
  }
  .padding-l {
    padding-left: 2rem;
  }
  .section-gallery {
    padding-inline: 1rem;
  }
  section {
    padding-inline: 5%;
    padding-block: 2rem;
  }
  #Nav {
    padding-inline: 50px !important;
  }
  .d-sm-flex > * {
    max-width: 33%;
    display: grid;
    align-content: normal;
  }
}
